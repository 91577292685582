<template>
  <div>
    <b-card no-body class="mb-0">
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="$refs['claimsList'].refresh()"
      >
        <b-table
          id="claimsList"
          slot="table"
          ref="claimsList"
          show-empty
          no-provider-filtering
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="search"
          :fields="filteredFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          :no-sort-reset="true"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
          :row-variant="'danger'"
          sticky-header="70vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(title)="data">
            <change-sms v-if="data.item.title" :SmsData="data.item.title" />
            <p v-else class="text-center">-</p>
          </template>

          <template #cell(status)="data">
            <p class="mb-0 font-weight-bold">
              <b-badge pill :class="colorStatus(data.item.status)[1]">{{
                colorStatus(data.item.status)[0]
              }}</b-badge>
            </p>
          </template>

          <template #cell(survey_rating)="data" >
            <div class="d-flex justify-content-center">
              <b-badge :class="data.item.survey_rating ? 'bg-light-warning' : 'bg-light-secondary'" pill>
              <feather-icon
                icon="StarIcon"
                size="18"
                :class="data.item.survey_rating ? 'text-warning cursor-pointer' : 'text-muted'"
                @click="data.item.survey_rating ? openSurvey(data.item):false"
              />
              </b-badge>
            </div>
          </template>
          <template #cell(tracking)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="ListIcon"
                size="20"
                :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                class="mr-50 cursor-pointer"
                v-b-tooltip.hover.top="'Tracking'"
                @click="openTracking(data.item.claim_request_id)"
              />
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
            <div class="mr-2 ">
                <p class="mb-0 font-weight-bold" :class="data.item.status != 2 ? 'text-muted' : 'text-info'">
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                    class="cursor-pointer"
                    @click="openViewClaims(data.item)"
                  />
                </p>
              </div>
            <feather-icon
              icon="MessageCircleIcon"
              size="20"
              variant="outline-primary"
              class="text-primary cursor-pointer mr-1"
              @click="openAttendClaimModal(data.item)"
            />
            </div>
          </template>
        </b-table>
      </filter-slot>
      <ModalTrackingEmployeeClaims
        v-if="showTraking"
        :claim="idClaimTraking"
        @hiddenModal="showTraking = false"
      />
    </b-card>

    <modal-view-client
      :id_claim="id_claim"
      v-if="openModalViewClient"
      @close="closeModalViewClient"
    />

    <ModalAttendEmployeeClaim
      v-if="showModalAttendEmployeeClaim"
      :claim-id="claimId"
      @close="closeModalAttendEmployeeClaim"
      @removeItem="$refs['claimsList'].refresh()"
    />
    <modal-survey-emoji-card
          v-if="openSurveyEmojiCard"
          @close="closeSurveyEmojiCard"
          :claim="claim"
        />
  </div>
</template>

<script>
import dataFilters from "@/views/commons/employee-claims/data/filters.data";
import ClaimsService from "@/views/commons/employee-claims/services/claim.service";
import { mapMutations, mapGetters, mapActions } from "vuex";
import ModalAttendEmployeeClaim from "@/views/commons/employee-claims/components/modal/ModalAttendEmployeeClaim.vue";
import ModalViewClient from "@/views/commons/employee-claims/components/modal/ModalViewClaims.vue";
import ModalTrackingEmployeeClaims from "@/views/commons/employee-claims/components/modal/TrackingEmployeeClaims.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";
import ModalSurveyEmojiCard from "@/views/commons/employee-claims/components/modal/ModalSurveyEmojiCard.vue";

export default {
  components: {
    ModalViewClient,
    ModalAttendEmployeeClaim,
    ModalTrackingEmployeeClaims,
    ChangeSms,
    ModalSurveyEmojiCard,
  },

  data() {
    return {
      fields: [
        {
          key: "created_by",
          sortable: false,
          label: "EMPLOYEE NAME",
          visible: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "code",
          sortable: false,
          label: "CODE",
          visible: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "title",
          sortable: true,
          label: "CLAIM",
          visible: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "status",
          sortable: true,
          label: "Status",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          sortable: true,
          label: "CREATION DATE",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
          formatter: (value) => {
            return this.$options.filters.myGlobalDay(value);
          },
        },
        {
          key: "survey_rating",
          label: "survey",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center pt-2",
        },
        {
          key: "tracking",
          label: "tracking",
          visible: true,
          thClass: "col-1 text-center",
          tdClass: "col-1 text-center",
        },
        {
          key: "actions",
          label: "Actions",
          visible: true,
          thClass: "col-1 text-center",
          tdClass: "col-1 text-center",
        },
      ],
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      department: null,
      createEmployeeOn: false,
      isRelate: false,
      username: null,
      selectedEmployeeId: null,
      openModalViewClient: false,
      sortBy: "10",
      sortDesc: true,
      showModalAttendEmployeeClaim: false,
      claimId: null,
      id_claim: null,
      spaceData: " ",
      showTraking: false,
      idClaimTraking: 0,
      claim: {},
      openSurveyEmojiCard: false,
    };
  },

  computed: {
    filteredFields() {
      if (this.statusRouter != 1) {
        this.fields[3].sortable = false; //column status
        
      }
      if(this.statusRouter == 1){
        console.log(this.statusRouter);
        this.fields[5].visible = false; //column survey
      }
      return this.fields.filter((fields) => fields.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
      S_DATA_UPDATE_CLAIM: "global-store/G_DATA_UPDATE_CLAIM",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    statusRouter() {
      return this.$route.meta.status;
    },
    isModuleClaim() {
      // if you are in the logistic module or RRHH
      return [17, 19].includes(this.moduleId);
    },
    tabStatus() {
      return this.$route.meta.status ?? 0;
    },
  },
  methods: {
    ...mapActions({
      A_COUNTER_EMPLOYEE_CLAIMS:
        "EmployeeClaimsStore/A_COUNTER_EMPLOYEE_CLAIMS",
    }),
    ...mapMutations({
      setEmplooyeId: "ManagementEmployeesStore/M_SET_S_EMPLOYEE_ID",
      updateCount: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    openViewClaims(item) {
      this.id_claim = item.claim_request_id;
      this.openModalViewClient = true;
      if (item.status == 2 && this.tabStatus == 1) {
        this.$set(item, "status", 3);
        this.A_COUNTER_EMPLOYEE_CLAIMS({
          counterUser: this.CounterEmployee.counterUser,
          counterModule: this.CounterEmployee.counterModule - 1,
        });
        this.updateCount({
          routeName: this.$route.matched[1].name,
          tag:
            this.CounterEmployee.counterUser +
            this.CounterEmployee.counterModule,
        });
      }
    },
    closeModalViewClient() {
      this.openModalViewClient = false;
      //this.$refs.claimsList.refresh();
    },
    openAttendClaimModal(item) {
      this.claimId = item.claim_request_id;
      this.showModalAttendEmployeeClaim = true;
      if (item.status == 2) {
        this.$set(item, "status", 3);
      }
    },

    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.claimsList.refresh();
    },
    resetSearch() {
      this.searchInput = "";
      this.$refs.claimsList.refresh();
    },
    async search(ctx) {
      try {
        const params = {
          page: this.paginate.currentPage,
          perPage: this.paginate.perPage,
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          orderby: ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
          status: this.statusRouter != 1 ? null : this.filter[2].model,
          moduleId: this.moduleId,
          attentionSection: this.isModuleClaim ? 1 : 0,
          roleId: this.currentUser.role_id,
          userId: this.currentUser.user_id,
          tabStatus: this.statusRouter,
          departament: this.isModuleClaim ? this.moduleId : null,
          allClaims: 0,
          originModule: this.isModuleClaim ? null : this.moduleId,
          myClaims: 1,
        };

        const data = await ClaimsService.getCLaims(params, ctx.currentPage);
        if (data.status == 200) {
          this.items = data.data.data;
          this.startPage = data.data.from;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = data.data.last_page;
          this.totalData = data.data.total;
          this.totalRows = data.data.total;
          this.toPage = data.data.to;
          return this.items;
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    colorStatus(type) {
      let selected;
      switch (type) {
        case 1:
          selected = ["Created", "bg-light-secondary"];
          break;
        case 2:
          selected = ["Pending", "bg-light-warning"];
          break;
        case 3:
          selected = ["In Revision", "bg-light-primary"];
          break;
        case 4:
          selected = ["Resolved", "bg-light-success"];
          break;
        case 5:
          selected = ["Rejected", "bg-light-danger"];
          break;
        default:
          break;
      }
      return selected;
    },
    closeModalAttendEmployeeClaim() {
      this.showModalAttendEmployeeClaim = false;
      // this.$refs.claimsList.refresh();
    },
    openTracking(_id) {
      this.idClaimTraking = { id: _id };
      this.showTraking = true;
    },
    openSurvey(item) {
      this.claim.id = item.claim_request_id;
      this.openSurveyEmojiCard = true;
    },
    closeSurveyEmojiCard(item) {
      this.openSurveyEmojiCard = false;
    },
  },
};
</script>
